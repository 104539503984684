var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/~gitbook/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c8d5f825fc9843eeee2d039c2903f1c83403487e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const dsn = process.env.SENTRY_DSN;
if (dsn) {
    Sentry.init({
        debug: false,
        dsn,
        integrations: [],
        sampleRate: 0.1,
        enableTracing: false,
        beforeSendTransaction: () => {
            return null;
        },
    });
}
